import React from 'react'
import { IoIosArrowRoundBack, IoMdArrowBack } from "react-icons/io";
import gobackCss from './css/goback.module.css'
import { useNavigate} from "react-router-dom";
export default function GobackButton() {
    const navigate = useNavigate();
    function onGoback() {
        navigate(-1)
    }
  return (
    <div className={gobackCss.wrapper}>
        <button onClick={onGoback}><IoMdArrowBack />Back</button>
    </div>
  )
}
