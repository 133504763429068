import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeletePageData, GetPageData } from "../../../api/PagesApi";
import PageListCss from "./css/PageList.module.css";

const PageList = () => {
  const [pageList, setPageList] = useState([]);

  async function getPageData() {
    let mpageList = await GetPageData();
    setPageList(mpageList);
  }
  const myNavigate = useNavigate();
  function onEditClick(item) {
    myNavigate(`edit?id=${item.id}`);
  }
  async function onDeleteClick(item) {
    if (window.confirm("do you wnat to delete this Page?")) {
      // await DeleteItemExpress('my_pages', item.id)
      await DeletePageData(item.id);
      window.location.reload(false);
    }
  }
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <section className={PageListCss.salesMain}>
      <h2>Page List</h2>
      <div className={PageListCss.innerSale}>
        <div className={PageListCss.tableSection}>
          <table>
            <tbody>
              <tr>
                <th>SL</th>
                <th>Page Name</th>
                <th>Page Slug</th>
                <th>Page Description</th>
                <th>Action</th>
              </tr>
              {pageList?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.page_name}</td>
                    <td>{item.page_slug}</td>
                    <td>{item.meta_description}</td>
                    <td>
                      <div className={PageListCss.action_buttons}>
                        <button
                          onClick={() => {
                            onEditClick(item);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            onDeleteClick(item);
                          }}
                          className={PageListCss.bg_red}
                          style={{ marginLeft: "10px" }}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* {TableItem} */}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PageList;
