import AllCombine from './AllCombine'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { store } from './ReduxFiles/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LogintoDashboard from './AllContent/ManageUser/LogintoDashboard';

function App() {


  return (
    <>
      <Provider store={store}>
      <ToastContainer/>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={ <AllCombine />}/>
          <Route path='/login' element={<LogintoDashboard />} />
        </Routes>
      </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
