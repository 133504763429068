import React, { useEffect, useState } from 'react'
import { GetEndDate, GetStartDate } from '../MyFunctions/MyFunctions'
// import './searchcomp.css'
import Select from 'react-select'
import { fetchInitCustomer, fetchSearchedCustomer, selectCustomerStatus, selectInitCustomer } from '../../ReduxFiles/Slices/CustomerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { GetCompleteSalesData, GetDeclineSalesData, GetHistorySalesData, GetReturnSalesData } from '../../api/SalesApi'
import salesSearchCompCss from './salesCss/SalesSearchComp.module.css'

const SalesSearchComp = ({passedFrom, setFilteredData, limitNumber }) => {
    const customerStatus = useSelector(selectCustomerStatus)
    const allCustomer = useSelector(selectInitCustomer)
    const dispatch = useDispatch()
    const [searchDate, setSearchDate] = useState()
    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSearchDate({ ...searchDate, [name]: value })
    }
  
    function onCustomerChange(data) {
     setSearchDate({ ...searchDate, customer_id: data.id })
    }
    const onInputDataChange = (inputValue, { action }) => {
        if (action === 'input-change') {
           if (inputValue.length >=3) {
                dispatch(fetchSearchedCustomer(inputValue))
           }
        }
      };
    

    async function searchClick() {
        let data = {}
        let myStartDate = ''
        let myEndDate = ''
        if (searchDate?.start_date && searchDate?.end_date) {
            myStartDate = new Date(GetStartDate(searchDate?.start_date)).getTime()
            myEndDate = new Date(GetEndDate(searchDate?.end_date)).getTime()
        }
        if (searchDate?.start_date && searchDate?.end_date && searchDate?.customer_id) {
            let myStartDate = new Date(GetStartDate(searchDate?.start_date)).getTime()
            let myEndDate = new Date(GetEndDate(searchDate.end_date)).getTime()
            data = {
                start_date:myStartDate,
                end_date:myEndDate,
                customer_id:searchDate.customer_id,
                clicked:1
            }
            searchDataFunction(data)
        } else if (searchDate?.start_date && searchDate?.end_date) {
            data = {
                start_date:myStartDate,
                end_date:myEndDate,
                clicked:2
            }
            searchDataFunction(data)
        } else if (searchDate?.customer_id) {
            data = {
                customer_id:searchDate.customer_id,
                clicked:3
            }
            searchDataFunction(data)
        } else if (searchDate?.invoice_no) {
            data = {
                invoice_no:searchDate?.invoice_no,
                clicked:4
            }
            searchDataFunction(data)
        }
        else {
            alert('start date & end date required or Select a Customer to Filter')
        }
    }
    async function resetClick() {
        if (!limitNumber) {
            limitNumber = 10
        }
        setSearchDate()
        switch (passedFrom) {
            case 'complete':
                CompleteData()
                break;
            case 'history':
                HistoryData()
                break;
            case 'rhistory':
                ReturnHistoryData()
                break;
            case 'declined':
                DeclinedData()
                break;
            default:
                break;
        }

        dispatch(fetchInitCustomer(10))
    }
    async function searchDataFunction(passedData) {
        //complete
        switch (passedFrom) {
            case 'complete':
                CompleteData(passedData)
                break;
            case 'history':
                HistoryData(passedData)
                break;
            case 'rhistory':
                ReturnHistoryData(passedData)
                break;
            case 'declined':
                DeclinedData(passedData)
                break;
            default:
                break;
        }
    }

    async function HistoryData(passedData) {
        let historyData = await GetHistorySalesData({...passedData})
        let sortArr = historyData.sort((a, b) => (a.id > b.id) ? -1 : 1)
        setFilteredData(sortArr)
    }
    async function DeclinedData(passedData) {
        let declineData = await GetDeclineSalesData(passedData)
        setFilteredData(declineData)
    }
    async function ReturnHistoryData(passedData) {
        let returnData = await GetReturnSalesData({...passedData })
        let tempArr = []
        returnData.forEach(element => {
            element = {
                ...element,
                discount: element.total_orprice * element.discountPercent/100
            }
            tempArr.push(element)
        });
        setFilteredData(tempArr)
    }
    async function CompleteData(passedData) {
        let data = await GetCompleteSalesData({limit:limitNumber, ...passedData})
        let tempArr = []
        data?.forEach(element => {
            let perdiscount = element.discountPercent*parseInt(element.total_orprice)/100
            let discount = element.total_orprice-element.total_price+perdiscount
            let total = element.total_orprice+element.deliveryCharge - discount
            let due = total - (element.receiveAmount + element.rprice)
            tempArr.push({...element, total, due})
        });
        setFilteredData(tempArr)
    }

    useEffect(() => {
        if (customerStatus === 'idle') {
            dispatch(fetchInitCustomer(10))
        }
    }, [])

    return (
        <div className={salesSearchCompCss.innerSale}>
            <div className={salesSearchCompCss.searchSection}>
                <div className={salesSearchCompCss.input_section}>
                    <MyInput labelText="Start Date*" type='date' inputName='start_date' placeholderText='dd/mm/yyyy' onDataChange={onInputChange} myValue={searchDate?.start_date} passedFunction={searchClick} />
                </div>
                <div className={salesSearchCompCss.input_section}>
                    <MyInput labelText="End Date*" type='date' inputName='end_date' placeholderText='dd/mm/yyyy' onDataChange={onInputChange} myValue={searchDate?.end_date} passedFunction={searchClick} />
                </div>
                <div className={salesSearchCompCss.input_section}>
                    <MyInput labelText="Invoice No*" type='number' inputName='invoice_no' placeholderText='invoice no' onDataChange={onInputChange} myValue={searchDate?.invoice_no} passedFunction={searchClick} />
                </div>
                <div className={salesSearchCompCss.input_section}>
                    <label>Customer Number*</label>
                <Select  className={salesSearchCompCss.select} options={allCustomer} getOptionLabel={(option)=> `${option.name} - ${option.phone}`} getOptionValue={(option)=>option.id} onChange={onCustomerChange} onInputChange={onInputDataChange}/>
                </div>            
                
            </div>
            <div className={salesSearchCompCss.searchSectionButton}>

                    <ButtonInput className='search' myValue={'Search'} onClickevent={searchClick} />
                    <ButtonInput mclass='resetClass' myValue={'Reset'} onClickevent={resetClick} />

                </div>
        </div>
        
    )
}

export default SalesSearchComp

const MyInput = ({ labelText, type, inputName, placeholderText, myValue, onDataChange, passedFunction }) => {
    const diplayBlock = {
        'display': 'block'
    }
    function onMykeyUp(e) {
        if (e.keyCode === 13) {
            passedFunction()
        }
    }
    return (
        <>
            <div className={salesSearchCompCss.inputAll}>
                <label style={diplayBlock}>{labelText}</label>
                <input type={type} name={inputName} placeholder={placeholderText} value={myValue ? myValue : ''} onChange={onDataChange} onKeyUp={onMykeyUp} />
            </div>
        </>
    )
}
const ButtonInput = ({ myValue, mclass, onClickevent }) => {
    return (
        <button className={mclass} onClick={onClickevent} >{myValue}</button>
    )
}
