import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { MdArrowBack } from "react-icons/md";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { selectProductList } from "../../ReduxFiles/Slices/ProductSlice";
import { selectUserInfo } from "../../ReduxFiles/Slices/UserSlice";
import {
  GetSingleCustomer,
  GetSingleCustomerOrderAddress,
} from "../../api/CustomerApi";
import {
  DeleteSalesItemsData,
  DeleteSalesOfferItem,
  GetFilterSalesOfferItem,
  SaveSalesItemsData,
  SaveSalesOfferItemsData,
  SaveSummeryData,
  UpdateSalesItemsData,
  UpdateSalesOfferItemsData,
} from "../../api/OrderInformationApi";
import { GetFilterProductVariance } from "../../api/ProductVarianceApi";
import {
  GetDetailsOrderItems,
  GetSingleOrder,
  UpdateOrderData,
} from "../../api/SalesApi";
import Spinner from "../smallThings/Spinner";
import PopUpLocation from "./PopUpLocation";
import SalesEditCss from "./salesCss/SalesEdit.module.css";
async function getDataFromDataBase(
  orderid,
  setOrderData,
  setTableData,
  setSpinner
) {
  setSpinner(true);
  let myData = await GetSingleOrder(orderid);
  let myCustomer = await GetSingleCustomer(myData[0].userID);
  let userAddress = await GetSingleCustomerOrderAddress(
    myData[0]?.user_address_id
  );
  let finalData = {
    ...myData[0],
    itemid: myData[0].id,
    order_created: myData[0].created_date,
    ...userAddress[0],
    AddressPhone: userAddress[0]?.Phone,
    ...myCustomer[0],
    deliveryCharge: myData[0].deliveryCharge,
  };
  setOrderData(finalData);
  let myNestedData = await GetDetailsOrderItems({ id: orderid });
  setTableData(myNestedData);
  setSpinner(false);
}

const SalesEdit = () => {
  const myParam = useParams();
  const userInfo = useSelector(selectUserInfo);
  const allProduct = useSelector(selectProductList);
  const [orderData, setOrderData] = useState();
  const [newVarianceData, setNewVarianceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [newTempItem, setNewTempItem] = useState();

  const [popAddress, setPopAddress] = useState(false);

  function EditAddressClick() {
    document.body.style.overflow = "hidden";
    setPopAddress(true);
  }

  function onDeliveryDateChange(e) {
    setOrderData({ ...orderData, delivery_date: e.target.value });
  }
  function onDeliveryChargeChange(e) {
    if (e.target.value < 0 || !e.target.value) {
      setOrderData({ ...orderData, deliveryCharge: 0 });
    } else {
      setOrderData({ ...orderData, deliveryCharge: parseInt(e.target.value) });
    }
  }
  function onDiscountChange(e) {
    let value = e.target.value;

    if (parseInt(value) < 0 || !value) {
      setOrderData({ ...orderData, discountPercent: 0 });
    } else {
      if (orderData.discountType === "percent") {
        setOrderData({ ...orderData, discountPercent: parseFloat(value) });
      } else {
        let myPercent = (parseFloat(value) * 100) / originalTotal();
        setOrderData({
          ...orderData,
          discountPercent: myPercent,
          discountType: "fixed",
        });
      }
    }
  }
  function onDiscountTypeChange(e) {
    setOrderData({ ...orderData, discountType: e.target.value });
  }

  function onQuantityChange(e, index) {
    let value = e.target.value;
    let myData = [];
    tableData.forEach((item, mindex) => {
      if (index === mindex) {
        item = { ...item, quantity: value };
        myData.push(item);
      } else {
        myData.push(item);
      }
    });
    setTableData(myData);
  }
  function onDecreaseClick(e, index) {
    let myData = [];
    tableData.forEach((item, mindex) => {
      if (index === mindex) {
        item = { ...item, quantity: item.quantity - 1 };
        myData.push(item);
      } else {
        myData.push(item);
      }
    });
    setTableData(myData);
    setOrderData({ ...orderData, discountType: "fixed", discountPercent: 0 });
  }
  function onIncreaseClick(e, index) {
    let myData = [];
    tableData.forEach((item, mindex) => {
      if (index === mindex) {
        item = { ...item, quantity: item.quantity + 1 };
        myData.push(item);
      } else {
        myData.push(item);
      }
    });
    setTableData(myData);
    setOrderData({ ...orderData, discountType: "fixed", discountPercent: 0 });
  }

  function onAddClick(e) {
    e.preventDefault();
    const variance_id = e.target.variance_id?.value;
    let myokData = tableData.slice();
    let dataAdd = true;
    if (newTempItem.hasVriance) {
      for (let index = 0; index < myokData.length; index++) {
        const element = myokData[index];
        if (element.variance_id === parseInt(variance_id)) {
          toast.warn("Product already exist with this variance!!!");
          dataAdd = false;
          break;
        } else {
          dataAdd = true;
        }
      }
    } else {
      for (let index = 0; index < myokData.length; index++) {
        const element = myokData[index];
        if (element.product_id === newTempItem.product_id) {
          toast.warn("Product already exist!!!");
          dataAdd = false;
          break;
        } else {
          dataAdd = true;
        }
      }
    }
    if (dataAdd) {
      myokData.push({ ...newTempItem, variance_id: parseInt(variance_id) });
      setTableData(myokData);
      setNewTempItem([]);
      setNewVarianceData([]);
      setOrderData({ ...orderData, discountType: "fixed", discountPercent: 0 });
    }
  }
  const [forDelete, setForDelete] = useState([]);

  function onRemoveClick(e, index) {
    let myokData = tableData.slice();
    let deleteData = myokData.splice(index, 1);
    if (deleteData[0].id) {
      let okDelete = forDelete.slice();
      okDelete.push(deleteData[0]);
      setForDelete(okDelete);
      setTableData(myokData);
    } else {
      setTableData(myokData);
    }
    setOrderData({ ...orderData, discountType: "fixed", discountPercent: 0 });
  }
  const myNavigate = useNavigate();

  async function onSubmitClick() {
    if (tableData.length > 0) {
      tableData.map(async (item) => {
        if (item.id) {
          let mitemID = item.id;
          let mQuery = `sales_id=${myParam.orderid} AND product_id=${item.product_id}`;
          let dataExisted = await GetFilterSalesOfferItem(mQuery);
          if (dataExisted.length > 0) {
            await UpdateSalesOfferItemsData(dataExisted[0].id, {
              quantity: item.quantity,
            });
          }
          await UpdateSalesItemsData(mitemID, { quantity: item.quantity });
        } else {
          delete item.name;
          delete item.Variance_name;
          if (item.promo) {
            let promoItem = {};
            allProduct.forEach((allItem) => {
              if (allItem.id === item.product_id) {
                promoItem = {
                  sales_id: myParam.orderid,
                  userID: item.userID,
                  product_id: allItem.id,
                  promoPrice: allItem.Promo_price,
                  original_price: allItem.Selling_price,
                  Start_date: allItem.Start_date,
                  End_date: allItem.End_date,
                  canceled: 0,
                  quantity: item.quantity,
                  created_date: new Date().getTime(),
                  mrp_price: item.mrp_price,
                  tp_price: item.tp_price,
                };
              }
            });
            await SaveSalesOfferItemsData(promoItem);
          }
          await SaveSalesItemsData({
            ...item,
            created_date: new Date().getTime(),
          });
        }
      });
      let newData = {
        grandTotal: totalCount(),
        delivery_date: orderData.delivery_date,
        deliveryCharge: orderData.deliveryCharge,
        discountPercent: orderData.discountPercent,
      };
      await UpdateOrderData(myParam.orderid, newData);

      forDelete?.map(async (deletItem) => {
        let mQuery = `sales_id=${myParam.orderid} AND product_id=${deletItem.product_id}`;
        let dataExisted = await GetFilterSalesOfferItem(mQuery);
        if (dataExisted.length > 0) {
          await DeleteSalesOfferItem(dataExisted[0].id);
        }
        await DeleteSalesItemsData(deletItem.id);
      });

      let nestedData = {
        ProssesedBy: userInfo.id,
        sale_id: myParam.orderid,
        created_date: new Date().getTime(),
        activity: "Sales Item Edited",
      };
      await SaveSummeryData(nestedData);
      myNavigate("/sales/history/");
    } else {
      console.log("no data");
    }
  }

  function originalTotal() {
    let total = 0;
    tableData?.forEach((item) => {
      total += item.quantity * item.orPrice;
    });
    return total;
  }
  function productDiscount() {
    let total = 0;
    tableData?.forEach((item) => {
      total += (item.orPrice - item.price) * item.quantity;
    });
    return total;
  }

  function totalCount() {
    let total = 0;
    tableData?.forEach((item) => {
      total += item.quantity * item.price;
    });

    return total;
  }

  const [mySpinner, setSpinner] = useState(false);

  useEffect(() => {
    getDataFromDataBase(
      myParam.orderid,
      setOrderData,
      setTableData,
      setSpinner
    );
  }, [myParam]);

  async function onProductChange(item) {
    if (item) {
      let mprice = item.Selling_price;
      let promo = 0;
      if (item.Promo_price > 0) {
        mprice = item.Promo_price;
        promo = 1;
      }
      let arrayData = {
        sales_id: myParam.orderid,
        price: mprice,
        promo: promo,
        quantity: 1,
        orPrice: item.Selling_price,
        name: item.en_sort_title,
        userID: orderData.userID || userInfo.id,
        product_id: item.id,
        created_date: new Date().getTime(),
        mrp_price: item.Selling_price,
        tp_price: item.Selling_price * 0.8,
      };
      //  console.log(arrayData)
      if (item.hasVariance) {
        arrayData = {
          ...arrayData,
          hasVriance: 1,
        };
        let myVariance = await GetFilterProductVariance(
          `product_id = ${item.id}`
        );
        setNewVarianceData(myVariance);
      } else {
        setNewVarianceData([]);
      }
      setNewTempItem(arrayData);
    } else {
      setNewVarianceData([]);
    }
  }

  return (
    <section className={SalesEditCss.salesMain}>
      {mySpinner ? <Spinner /> : ""}
      <div className={SalesEditCss.innerSale}>
        {popAddress ? (
          <div className={SalesEditCss.popupLocation_wrapper}>
            <div className={SalesEditCss.popupLocation}>
              <PopUpLocation
                setPopAddress={setPopAddress}
                orderData={orderData}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <h2 className={SalesEditCss.saleHeading}>Order Edit</h2>
        <NavLink to={"/sales/history"}>
          <MdArrowBack />
        </NavLink>

        <div className={SalesEditCss.orderdetaislInner}>
          <h3>Invoice #{orderData?.itemid}</h3>
          <div className={SalesEditCss.customerSection}>
            <div className={SalesEditCss.customerDetails}>
              <h4>Customer Details *</h4>
              <p>
                <strong>Customer Name</strong> : {orderData?.name}
              </p>
              <p>
                <strong>Phone</strong> : {orderData?.AddressPhone}
              </p>
              <p>
                <strong>Division</strong> :{orderData?.Divition}
              </p>
              <p>
                <strong>District</strong> : {orderData?.District}
              </p>
              <p>
                <strong>area</strong> : {orderData?.Area}
              </p>
              <div className={SalesEditCss.addressSec}>
                <p>
                  <strong>Address</strong> : {orderData?.Address}
                </p>

                <FaRegEdit onClick={EditAddressClick} />
              </div>
            </div>
            <div className={SalesEditCss.orderDetails}>
              <h4 className={SalesEditCss.highlighter}>Order Details *</h4>
              <p>
                <strong>Order Date :</strong> {}{" "}
              </p>
              <p>
                <strong>Payment Type :</strong> {orderData?.paymentType}
              </p>
            </div>
          </div>

          <div className={SalesEditCss.input_section}>
            <div className={SalesEditCss.input_wrapper}>
              <label>Delivery Date</label>
              <input
                type={"date"}
                value={orderData?.delivery_date ? orderData?.delivery_date : ""}
                onChange={onDeliveryDateChange}
              />
            </div>
            <div className={SalesEditCss.input_wrapper}>
              <label>Delivery Charge</label>
              <input
                onChange={onDeliveryChargeChange}
                type={"number"}
                value={
                  orderData?.deliveryCharge ? orderData?.deliveryCharge : 0
                }
              />
            </div>
            <div className={SalesEditCss.input_wrapper}>
              <label>Discount</label>
              <div className={SalesEditCss.inner_input}>
                <select onChange={onDiscountTypeChange}>
                  <option value="fixed">Ammount</option>
                  <option value="percent">Percent</option>
                </select>
                <input
                  type={"number"}
                  onChange={onDiscountChange}
                  defaultValue="0"
                />
              </div>
            </div>
          </div>

          <form className={SalesEditCss.add_new_product} onSubmit={onAddClick}>
            <div className={SalesEditCss.SalesOrderSearchEdit}>
              <label>Item Name:</label>
              <Select
                options={allProduct}
                getOptionLabel={(option) => `${option.en_sort_title}`}
                getOptionValue={(option) => `${option.id}`}
                className={SalesEditCss.my_select}
                required
                name="product_id"
                isClearable
                onChange={onProductChange}
              />
              {newVarianceData.length > 0 ? (
                <Select
                  options={newVarianceData}
                  getOptionLabel={(option) => `${option.Variance_name}`}
                  getOptionValue={(option) => `${option.id}`}
                  className={SalesEditCss.my_select}
                  required
                  name="variance_id"
                  isClearable
                />
              ) : (
                ""
              )}
              <button>Add</button>
            </div>
          </form>

          <div className={SalesEditCss.tableSection}>
            <table>
              <tbody>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total Ammount</th>
                  <th>Action</th>
                </tr>
                {tableData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {item.name}
                        {item.Variance_name ? `-(${item.Variance_name})` : ""}
                        {item.attr_value_name
                          ? `-(${item.attr_value_name})`
                          : ""}
                      </td>
                      <td>{item.orPrice}</td>
                      <td>
                        {
                          <div className={SalesEditCss.quantity_edit}>
                            <p
                              onClick={(e) => {
                                onDecreaseClick(e, index);
                              }}
                            >
                              -
                            </p>
                            <input
                              type="number"
                              value={item.quantity ? item.quantity : 0}
                              onChange={(e) => {
                                onQuantityChange(e, index);
                              }}
                            />
                            <p
                              onClick={(e) => {
                                onIncreaseClick(e, index);
                              }}
                            >
                              +
                            </p>
                          </div>
                        }
                      </td>
                      <td>{item.quantity * item.orPrice}</td>
                      <td className={SalesEditCss.cross_icon}>
                        {
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={(e) => {
                              onRemoveClick(e, index);
                            }}
                          />
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className={SalesEditCss.tableDetails}>
              <p>Previous Ammount : {orderData?.grandTotal} tk</p>
              <p>Delivery Charge : {orderData?.deliveryCharge} tk</p>

              <p>
                Now Total Ammount :{" "}
                {originalTotal() + orderData?.deliveryCharge} tk
              </p>

              <p>
                Discount :{" "}
                {productDiscount() +
                  (orderData?.discountPercent
                    ? Math.round(
                        (originalTotal() * orderData.discountPercent) / 100
                      )
                    : 0)}{" "}
                tk
              </p>
              <p>
                Grand Total :{" "}
                {totalCount() +
                  orderData?.deliveryCharge -
                  (orderData?.discountPercent
                    ? (originalTotal() * orderData.discountPercent) / 100
                    : 0)}{" "}
                tk
              </p>
              <button onClick={onSubmitClick}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SalesEdit;
