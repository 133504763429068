import React, { useEffect, useRef } from 'react'
import { toast } from 'react-toastify';
import AddNewMenuCss from './css/AddNewMenu.module.css'
const AddNewMenu = ({ mstate, stateValue, item }) => {


    const tabItem = useRef()

    function CheckPreviousData(index) {
        const requiredFields = ['en_sort_title', 'en_long_title', 'bn_sort_title', 'bn_long_title', 
                                'selected_catagory_id', 'selected_brand_id', 'selectedUnit', 'itemImage'];
      
        for (const field of requiredFields) {
          if (!item[field]) {
            toast.warn('Please Fill Required Field')
            mstate(1);
            return; // Exit the function if any required field is missing
          }else{
            mstate(index)
          }
        }
      
        if (stateValue===2 && !item.Selling_price && index===3) {
          mstate(stateValue);
          toast.warn('Please Fill Required Field')
        }else{
            mstate(index);
        }
        if (stateValue===2 && !item.Selling_price && index===4) {
          mstate(stateValue);
          toast.warn('Field')
        }else{
            mstate(index);
        }
      }

    function changeUi(tabnumber) {
        
        mstate(tabnumber)
        // CheckPreviousData(data)
    }
    return (
        <div ref={tabItem} className={AddNewMenuCss.addnewtab}>
        <div className={AddNewMenuCss.tabSection}>
            <h4 onClick={()=>{changeUi(1)}}  className={ stateValue === 1 ? AddNewMenuCss.tabActive:''}>Item Information</h4>
            <h4 onClick={()=>{changeUi(2)}}  className={ stateValue === 2 ? AddNewMenuCss.tabActive:''}>Item Price</h4>
            <h4 onClick={()=>{changeUi(3)}}  className={ stateValue === 3 ? AddNewMenuCss.tabActive:''} >Product Attributes</h4>
            <h4 onClick={()=>{changeUi(4)}}  className={ stateValue === 4 ? AddNewMenuCss.tabActive:''}>Uplode Image</h4>
        </div>
            
        </div>
    )
}

export default AddNewMenu