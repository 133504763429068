import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useParams, useSearchParams } from 'react-router-dom'
import ComplainCategories from './SettingComponents/ComplainCategories'
import ComplainPriority from './SettingComponents/ComplainPriority'
import ComplainType from './SettingComponents/ComplainType'
import ComplainSettingMainCss from './css/ComplainSettingMain.module.css'


const ComplainSettingMain = ({activity}) => {
    const [activeCustom, setActiveCustom] = useState(activity)
    const [searchedParam, setSearchedParam] = useSearchParams()
    const myParams = useParams()

    return (
        <>
            <section className={ComplainSettingMainCss.salesMain}>
                <div className={ComplainSettingMainCss.innerSale}>
                    <h2 className={ComplainSettingMainCss.saleHeading}>Complain Setting</h2>

                    <div className={ComplainSettingMainCss.sub_menu_section}>
                    <Link className={activeCustom===1 ?`${ComplainSettingMainCss.activeCustom}`:''} to={'manage-customer/complain/complain_setting?page=complainpriorities'} onClick={()=>{setActiveCustom(1)}}>Complain Priorities</Link>

                    <Link className={activeCustom===2 ?`${ComplainSettingMainCss.activeCustom}`:''} to={'manage-customer/complain/complain_setting?page=complaintype'} onClick={()=>{setActiveCustom(2)}}>Complain Type</Link>

                    <Link className={activeCustom===3 ?`${ComplainSettingMainCss.activeCustom}`:''} to={'manage-customer/complain/complain_setting?page=complaincategories'} onClick={()=>{setActiveCustom(3)}}>Complain Categories</Link>
                </div>
                
                <div>
               
                    {activeCustom===1 ? <ComplainPriority/>:''}
                    {activeCustom===2? <ComplainType/>:""}
                    {activeCustom===3? <ComplainCategories/>:""}

                </div>
                </div>
              
               
            </section>

        </>
    )
}

export default ComplainSettingMain