import React from 'react'
import { NavLink, Route, Routes, useParams } from 'react-router-dom'
import ComplainFunctions from './Actions/ComplainFunctions'
import ComplainEdit from './Actions/ComplainEdit'
import AddComplain from './AddComplain'
import ComplainList from './ComplainList'
import ComplainSettingMain from './ComplainSettingMain'
import ManageComplainMainCss from './css/ManageComplainMain.module.css'


const ManageComplainMain = () => {
  const myParams = useParams()

  return (
    <>
      <div className={ManageComplainMainCss.menupart}>
        <ul className={ManageComplainMainCss.menuitems}>
          <NavLink  to={"complain"}>
            <li >Manage Complains</li>
          </NavLink>
          <NavLink to={"complain_setting"}>
            <li >Complain Setting</li>
          </NavLink>
          <NavLink to={"add_complain"}>
            <li >Add Complain</li>
          </NavLink>
        </ul>
      </div>
        
        <Routes>
          <Route path='complain' element={<ComplainList/>}/>
          <Route path='complain_setting/*' element={<ComplainSettingMain/>}/>
          <Route path='add_complain' element={<AddComplain/>}/>
        </Routes>
    
    </>
  )
}

export default ManageComplainMain

