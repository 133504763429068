import { useState } from 'react'
import { UpdateProductImage } from '../../../../../api/ProductListApi'
import { toast } from 'react-toastify'
import { CheckRatio } from '../../../../../api/GlobalFunctions'
import uplodaProductImageCss from '../css/uploadProductImage.module.css'

const UploadProductImage = ({popUp, setPopUp}) => {
  const [shwoItemImage, setShowItemImage] = useState('')

   async function onImageUpload(e) {
        e.preventDefault()
        const product_image = e.target.product_image.files[0]
       try {
       await UpdateProductImage(popUp.id, product_image, popUp.itemImageName)
        setPopUp()
        toast.success('Product Image Updated')
       } catch (error) {
        toast.error(error.message)
       }
    }

    function onImageChange(e) {
      const images = e.target.files[0]
      CheckRatio(images, (isImageOK)=>{
          if (isImageOK) {
            ImageShow(images)
          }else{
            e.target.value = null
            setShowItemImage('')
          }
      })
      
    }
    function ImageShow(mImage, identity) {
      if (mImage) {
        let myurl = URL.createObjectURL(mImage)
        setShowItemImage(myurl)
      }else{
        setShowItemImage('')
      }

  }

  return (
    <div className={uplodaProductImageCss.wrapper}>
        <div className={uplodaProductImageCss.holder}>
            <h2>Upload Product Image Here</h2>
            <div id='image_show' className='disNone'> </div>
            <div className={uplodaProductImageCss.content}>
                  <form onSubmit={onImageUpload}>
                     <div className={uplodaProductImageCss.input_holder}>
                     <label>Product Image :</label>
                      <input name='product_image' type='file' accept='image/webp' multiple={false} onChange={onImageChange} required/>
                     </div>
                      <div className={uplodaProductImageCss.image_holder}>
                        {shwoItemImage ? <img src={shwoItemImage} alt='fake url'/>:''}
                      </div>
                      
                  </form>
            </div>
              <div className={uplodaProductImageCss.btn_group}>
                <button type='submit'>Upload</button>
                <button type='button' onClick={()=>setPopUp()}>Close</button>
              </div>
        </div>
    </div>
  )
}

export default UploadProductImage