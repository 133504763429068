import MyAxios from "axios";
import { API_LINK } from "./APILink";
const MY_API_LINK = `${API_LINK}user/`;

const configdata = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function GetTotalCustomer() {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}count/total`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetSingleCustomer(id) {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}${id}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetLimitCustomer(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}get-limit-user`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetSearchedCustomer(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}search-user`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function SaveCustomerDataFromBack(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}admin-save`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateCustomerPasswordFromBack(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}update-pass`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function GetSingleCustomerOrderAddress(id) {
  try {
    let result = await MyAxios.get(`${API_LINK}address/single/${id}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetAllCustomer() {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}all`);
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function SaveCustomerData(data) {
  try {
    let result = await MyAxios.post(`${MY_API_LINK}add`, data, configdata);
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function CheckExisitingUser(phone) {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}phone/${phone}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}

// customer address api

export async function GetCustomerAddressList(query) {
  try {
    let result = await MyAxios.get(`${API_LINK}address/filter/${query}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
