import { Line } from "rc-progress";
import React, { useEffect, useState } from "react";
import {
  FaChartBar,
  FaChartLine,
  FaStar,
  FaStarHalfAlt,
  FaUsers,
} from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdOutlinePendingActions } from "react-icons/md";
import { Link } from "react-router-dom";
import { GetDashboardData } from "../../api/CombineApi";
import { ServerTimeToFormateTime } from "../../api/GlobalFunctions";
import { GetProductImageLink } from "../../api/ProductListApi";
import MyChart from "../DashboardContent/MyChart";
import dashCss from "./dashboard.module.css";

async function getData(setData) {
  const data = await GetDashboardData();
  setData(data || {});
  console.log(data);
}

export default function Dashboard() {
  const [dashData, setDashData] = useState({});
  const [actionActive, setAction] = useState();
  useEffect(() => {
    getData(setDashData);
  }, []);
  function actionClick(item) {
    setAction(item.id);
  }
  function actionClose() {
    setAction("");
  }
  const dashContent = [
    {
      name: "Total Sale",
      amount: "final_sales",
      icon: <FaChartLine />,
      percent: 75,
      color: "#28a745",
      link: "/",
    },
    {
      name: "Today Sale",
      amount: "total_sale",
      count: "total_orders",
      icon: <FaChartBar />,
      percent: 85,
      color: "#6610f2",
      link: "/sales/history",
    },
    {
      name: "Pending Orders",
      amount: "pending_total",
      count: "pending_orders",
      icon: <MdOutlinePendingActions />,
      percent: 45,
      color: "#ffc107",
      link: "/sales/pending",
    },
    {
      name: "Total Customer",
      amount: "total_user",
      icon: <FaUsers />,
      percent: 72,
      color: "#e83e8c",
      link: "/manage-customer/customer-list",
    },
  ];

  const startRattings = [
    { title: "5 Start", percent: 63, color: "#28a745" },
    { title: "4 Start", percent: 20, color: "#6610f2" },
    { title: "3 Start", percent: 10, color: "#fd7e14" },
    { title: "2 Start", percent: 5, color: "#e83e8c" },
    { title: "1 Start", percent: 2, color: "#dc3545" },
  ];

  return (
    <div className={dashCss.wrapper}>
      {dashContent.map((item, index) => {
        return (
          <Link to={item.link} key={index} className={dashCss.items}>
            <div className={dashCss.items_holder}>
              <p className={dashCss.title}>{item.name}</p>
              <h3 className={dashCss.amount}>
                {dashData[item.amount]
                  ? parseFloat(dashData[item.amount])?.toFixed(2)
                  : "0"}{" "}
                {item.count
                  ? `(${dashData[item.count] ? dashData[item.count] : "0"})`
                  : ""}
              </h3>
            </div>
            <p className={dashCss.icons}> {item.icon}</p>
          </Link>
        );
      })}
      <div className={dashCss.chart}>
        <div className={dashCss.chart_wrap}>
          <h4>Day Wise Sales</h4>
          <MyChart />
        </div>
      </div>
      <div className={dashCss.top_selling}>
        <h4>Top Selling Product</h4>
        <div className={dashCss.product_wrapper}>
          {dashData.topSelling?.map((item, index) => {
            return (
              <div key={index} className={dashCss.product_items}>
                <div className={dashCss.image_holder}>
                  <ProductImageShow name={item.itemImageName} />
                </div>
                <div className={dashCss.product_info}>
                  <p>{item.product_name}</p>
                  <p className={dashCss.quantity}>{item.quantity}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={dashCss.latest_order}>
        <h4>Latest Orders</h4>
        <div className={dashCss.latest_table}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Customer Name</th>
                <th>Order Id</th>
                <th>Date</th>
                <th>Total</th>
                <th>District</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dashData.latestOrders?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{item.name}</td>
                    <td>#{item.id}</td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td>{item.grandTotal}</td>
                    <td>{item.District}</td>
                    <td className={dashCss.action_wrapper}>
                      <button onClick={() => actionClick(item)}>
                        <HiDotsHorizontal />
                      </button>
                      {actionActive === item.id ? (
                        <div className={dashCss.action_holder}>
                          <button
                            className={dashCss.close}
                            onClick={() => actionClose(item)}
                          >
                            <IoClose />
                          </button>
                          <Link to={""}>View Details</Link>
                          <Link to={`/sales/pending`}>Panding Sales</Link>
                          <Link to={`/sales/history`}>Sales History</Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className={dashCss.reviews}>
        <h4>Customer Reviews</h4>
        <div className={dashCss.review_info}>
          <div className={dashCss.t_stars}>
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStarHalfAlt />
          </div>
          <p>(4.5 ratting out of 5)</p>
        </div>
        <div className={dashCss.review_holder}>
          {startRattings.map((item, index) => {
            return (
              <div className={dashCss.star_holder} key={index}>
                <p>{item.title}</p>
                <div className={dashCss.stars}>
                  <Line
                    percent={item.percent}
                    strokeWidth={8}
                    strokeColor={item.color}
                    trailWidth={8}
                  />
                </div>

                <p>{`${item.percent}`.padStart(0, 2)}%</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const ProductImageShow = ({ name }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    async function getLink() {
      if (name) {
        let imageInfo = await GetProductImageLink({ image_name: name });
        if (imageInfo.imageExist) {
          setImage(imageInfo.url);
        } else {
          setImage("");
        }
      } else {
        setImage("");
      }
    }
    getLink();
  }, [name]);

  return <> {image ? <img src={image} alt={name} /> : ""}</>;
};
