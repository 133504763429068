import React, { useEffect,useState } from 'react'
import manageUserCss from './css/manageUser.module.css'
import { GetUserImage,UpdateBackUserData } from '../../../api/BackendUserApi'
import { useDispatch, useSelector } from 'react-redux'
import { FaInfoCircle } from "react-icons/fa";
import { MdEditSquare } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import DummyImage from '../../../assets/dummyuser.png'
import { fetchAllUser, selectActiveUserList, selectBackUserStatus, selectUserInfo } from '../../../ReduxFiles/Slices/UserSlice'
import { Link } from 'react-router-dom'
import AddnewUser from './AddnewUser';
import EditManageUser from './EditManageUser';
export default function ManageUser() {
  const dispatch = useDispatch()
  const userData = useSelector(selectActiveUserList)
  const userStatus = useSelector(selectBackUserStatus)
  const currentUser = useSelector(selectUserInfo)
  const [alertMessage, setAlertMessage] = useState()
  const [profileData,setProfileData]=useState()
  const [addUserShow,setAddUserShow]=useState(false)
  const [editManage,setEditMagage]=useState(false)
  const [editData,setEditData]=useState([])
  console.log(userData)
  console.log(editData)
  function onAddNewUser(){
    setAddUserShow(true)
  }
  function onInfoClick(e,item){
    setProfileData(item)
  }
  function onEditClick(item){
    setEditMagage(true)
    setEditData(item)
  }   
 async function onDeleteClick(item){
   userData.forEach(async (element) => {
    if(element.id===item.id){
      if(item.id===currentUser.id){
        setAlertMessage("You Cannot Delete Your Own ID")
      }else{
          if(item.userrole<currentUser.rule){
            setAlertMessage("You Cannot Delete This user")
          }else{
            if(window.confirm("Do You Want To Delete this user")){
             await UpdateBackUserData(item.id,{active:0})
              dispatch(fetchAllUser())
            }
          }
      }
    }
   });
}
  function userRuleText(number){
    let text=""
    if(number===1){
      text="Admin"
    }else if(number===2){
      text="Editor"
    }else if(number===3){
      text="Moderator"
    }
    return text
  }

  const TableItem=userData.map((item,index)=>{

   return <MyTable key={index} sl={index+1} name={item.fullName} userName={item.userName} rule={userRuleText(item.userrole
    )} action={<div className={manageUserCss.action_Wrapper}> <button onClick={(e)=>{onInfoClick(e,item)}}><FaInfoCircle /></button>  <button onClick={(e)=>onEditClick(item)}><MdEditSquare /></button>  <button onClick={(e)=>{onDeleteClick(item)}}><RiDeleteBin6Line /></button> </div>} />
  })
  return (
   <div className={manageUserCss.wrapper}>
     {alertMessage ? <AlertMessage message={alertMessage} setAlertMessage={setAlertMessage} /> : ""}
    {addUserShow?  <AddnewUser setAddUserShow={setAddUserShow}/> :""}
    {editManage?  <EditManageUser editData={editData} setEditMagage={setEditMagage}/> :""}
     
        {profileData? <ProfileView profileData={profileData} setProfileData={setProfileData}/>:""}
        <div className={manageUserCss.holder}>
            <h3>User List</h3>
            <div className={manageUserCss.header_button_wrapper}>
                <button onClick={onAddNewUser}>Add New User</button>
            </div>
            <div className={manageUserCss.table_wrapper}>
          <table>
            <tbody>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>User Name</th>
                <th>Rule</th>
                <th>Action</th>
              </tr>
              {TableItem}
            </tbody>
          </table>
        </div>
        </div>
   </div>
)

}

const AlertMessage = ({ message, setAlertMessage }) => {

  function alertClick() {
    setAlertMessage()
  }
  const zindex = {
    zIndex: 1000
  }
  return (
    <div style={zindex} className={manageUserCss.AlertMessage}>
      <h2>{message}</h2>
      <button onClick={alertClick}>OK</button>
    </div>
  )
}
const MyTable=({sl,name,userName,rule,action})=>{
  return <tr>
    <td>{sl}</td>
    <td>{name}</td>
    <td>{userName}</td>
    <td>{rule}</td>
    <td>{action}</td>
  </tr>
}



const ProfileView=({profileData,setProfileData})=>{


  const userInput=[
    {label:"Name",value:profileData.fullName},
    {label:"Phone",value:profileData.phone},
    {label:"Email",value:profileData.email},
    {label:"Bio",value:profileData.userbio},
    {label:"Rule",value:userRole(profileData.userrole)},
    ]
    function userRole(number){
      let text=""
      if(number===1){
        text="Admin"
      }else if(number===2){
        text="Editor"
      }else if(number===3){
        text="Moderator"
      }
      return text
    }
    function onCloseProfileView(){
      setProfileData("")
    }
  return  <div className={manageUserCss.info_pop_Wrapper}>
    <div className={manageUserCss.info_pop_holder}>
        <div className={manageUserCss.image_wrapper}>
          <AvatarComp name={profileData.userImageName}/>
        </div>
        {
          userInput.map(item=>{
         return <div className={manageUserCss.info_wrapper}>
              <p>{item.label} : </p>
              <span>{item.value}</span>
          </div>
          })
        }
        <div className={manageUserCss.button_Wrpper}>
           <button onClick={onCloseProfileView}>Close</button>
        </div>
       
    </div>
</div>
}

const AvatarComp=({name})=>{
  const [image,setImage]=useState()
  async function getLink() {
    if(name){
      let imageInfo=await GetUserImage({image_name:name})
      if(imageInfo.imageExist){
        setImage(imageInfo.url)
      }else{
        setImage(DummyImage)
      }
      
    }else{
     
    }
  }
console.log(image)
  useEffect(()=>{
    getLink()
  },[])
return<>
 <img className={manageUserCss.userImage} src={image} alt='avatar' />
</>
}



  

