import CustomerListCss from './css/CustomerList.module.css'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInitCustomer, fetchSearchedCustomer, selectCustomerStatus, selectInitCustomer } from '../../ReduxFiles/Slices/CustomerSlice'
import { GetLimitCustomer } from '../../api/CustomerApi'
import { LocalTimeStampToDateOnly } from '../MyFunctions/MyFunctions'

import { toast } from 'react-toastify'
import AddressView from './models/AddressView'
import EditCustomer from './models/EditCustomer'
import ProfileView from './models/ProfileView'
import { Link } from 'react-router-dom'
import { FaEye,FaEdit } from "react-icons/fa";
import { IoMdInformationCircle } from "react-icons/io";

const CustomerList = () => {
  const dispatch = useDispatch()
  const userData = useSelector(selectInitCustomer)
  const [myUserData, setMyUserData] = useState([])
  const customerStatus = useSelector(selectCustomerStatus)
  const [editUserData, setEditUserData] = useState()
  const [alertMessage, setAlertMessage] = useState()
  const [profileData, setProfileData] = useState()
  const [addressData, setAddressData] = useState()

  useEffect(() => {
    if (customerStatus === 'idle') {
      dispatch(fetchInitCustomer())
    }
    setMyUserData(userData)
  }, [customerStatus])

  function onDeleteClick() {
    document.body.style.overflow = 'auto'
    setAlertMessage('delete will work later')
  }

  function onEditClick(e, item) {
    setEditUserData(item)
  }
  function onInfoClick(e, item) {
    // console.log(item)
    setProfileData(item)
  }
  function onAddressViewClick(e, item) {
    setAddressData(item)
  }
  
  const myref = useRef()

  function onResetClick() {
    dispatch(fetchInitCustomer())
    myref.current.value = ''
  }

 async function onDataChange(e) {
    let inputValue = e.target.value
    if (inputValue.length >= 3) {
      dispatch(fetchSearchedCustomer(inputValue))
    } else {
      dispatch(fetchInitCustomer())
    }
  }
  const [myOffset, setMyOffset] = useState(10)
  async function onPreClick() {
    let offset = myOffset-10
    if (offset>0) {
      let data = await GetLimitCustomer({offset:offset})
    setMyOffset(myOffset-10)
    setMyUserData(data)
    }else{
      toast.warn('No Previous Data Found')
    }
  }
 async function onNextClick() {  
  let data = await GetLimitCustomer({offset:myOffset+10})
  setMyOffset(myOffset+10)
  setMyUserData(data)
  }

  return (

    

      <section className={CustomerListCss.salesMain}>
          {alertMessage ? <AlertMessage message={alertMessage} setAlertMessage={setAlertMessage} /> : ""}


        {editUserData &&  <EditCustomer popUp={editUserData} setPopUp={setEditUserData}/>}
        {profileData ? <ProfileView popUp={profileData} setPopUp={setProfileData} /> : ""}
        {addressData ? <AddressView popUp={addressData} setPopUp={setAddressData} /> : ""}


        <div className={CustomerListCss.innerSale}>
          <h2 className={CustomerListCss.saleHeading} >Manage Customer</h2>
          <div className={CustomerListCss.filter_sections}>
            <div className={`${CustomerListCss.input_section} ${CustomerListCss.search_product}`}>
              <input ref={myref} name='nmae' type='search' onChange={onDataChange} placeholder={'customer name or phone number'} autoComplete='off' />
            </div>
            <div className={`${CustomerListCss.buttonWrapper} ${CustomerListCss.search_product}`}>
              <button onClick={onResetClick}>Reset</button>
            </div>
          </div>
          <div className={CustomerListCss.tableSection}>
            <table>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>user id</th>
                    <th>Joined</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Total Order</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
              <tbody>
                {myUserData.map((item, index) => {
                  return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>#{item.id}</td>
                    <td>{LocalTimeStampToDateOnly(item.created_date)}</td>
                    <td>{item.name}</td>
                    <td>{item.email || '-'}</td>
                    <td><Link to={`/admin/report/customer_ledger/view_customer_ledger?phone=${item.phone}`} target='blank'>{item.total_order}</Link></td>
                    <td><FaEye onClick={(e) => { onAddressViewClick(e, item)}}/></td>
                    <td>{item.phone}</td>
                    <td><div className={CustomerListCss.userIcon}>
                     
                      <IoMdInformationCircle onClick={(e) => { onInfoClick(e, item) }} />
               
                      <FaEdit onClick={(e) => { onEditClick(e, item) }}/>
                    </div>
                    </td>
                  </tr>
                })}
               {/* <FontAwesomeIcon icon={faTrashCan} onClick={(e) => { onDeleteClick(e, item); document.body.style.overflow = 'hidden' }} /> */}
              </tbody>
            </table>
            <div className={CustomerListCss.btn_group}>
                <button onClick={onPreClick}>Pre</button>
                <button onClick={onNextClick}>Next</button>
            </div>
          </div>
        </div>
      </section>
  )
}

export default CustomerList

const AlertMessage = ({ message, setAlertMessage }) => {
  // yeasin
  const cancelClick = () => {
    setAlertMessage()
    document.body.style.overflow = 'auto'
  }

  function alertClick() {
    document.body.style.overflow = 'hidden'
    setAlertMessage()
  }
  const zindex = {
    zIndex: 1000
  }
  return (
    <div className={CustomerListCss.AlertMessage_wrapper}>
      <div style={zindex} className={CustomerListCss.AlertMessage}>
        <h2>{message}</h2>
        <div className={CustomerListCss.alert_btn}>
          <button onClick={alertClick}>Delete</button>
          <button onClick={cancelClick}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
