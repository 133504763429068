import React, { useEffect, useState } from 'react'
import { FaBars, FaCheck, FaInfoCircle, FaRegEdit, FaShoppingBag } from 'react-icons/fa'
import { FaRightFromBracket } from "react-icons/fa6"
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchAllUser, selectBackUserStatus } from '../../ReduxFiles/Slices/UserSlice'
import { API_LINK } from '../../api/APILink'
import { ServerTimeToFormateTime } from '../../api/GlobalFunctions'
import { GetHistorySalesData } from '../../api/SalesApi'
import GobackButton from '../global/GobackButton'

import LoadingShow from '../smallThings/LoadingShow'
import SalesSearchComp from './SalesSearchComp'
import ActivityViewModel from './models/ActivityViewModel'
import CollectOption from './models/CollectOption'
import CustomerInformationModel from './models/CustomerInformationModel'
import DeliveryBoyAssign from './models/DeliveryBoyAssign'
import PackagingPop from './models/PackagingPop'
import SaleHisCss from './salesCss/SaleHistory.module.css'


const SaleHistory = ({ mtableData }) => {
    const dispatch = useDispatch()
    const selectUserStatus = useSelector(selectBackUserStatus)
    const [tableData, setTableData] = useState([])
    const [showUser, setShowUser] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [showPackagingBox, setshowPackagingBox] = useState(false)
    const [packagingData, setPackagingData] = useState()
    const [deliveryBoyData, setDeliveryBoyData] = useState()
    const [activityView, setActivityView] = useState()

    async function customerClick(item) {
        setShowUser(item)
        document.body.style.overflow = 'hidden'
    }

    async function activityViewClick(e, item) {
        setActivityView(item)
        document.body.style.overflow = 'hidden'
    }

    const [deliveryDoneInfo, setDeliveryDoneInfo] = useState()
    function deliveryAgentClick(e, item) {
        setDeliveryDoneInfo(item)
        document.body.style.overflow = 'hidden'
    }



    function deliveryBoyAssign(e, item) {
        setDeliveryBoyData(item)
        document.body.style.overflow = 'hidden'
    }

    function PackagingClick(e, item) {
        setshowPackagingBox(true)
        setPackagingData(item)
    }

    async function getTableData() {
        try {
            setSpinnerShow(true)
            let historyData = await GetHistorySalesData()
            let sortArr = historyData?.sort((a, b) => (a.id > b.id) ? -1 : 1)
            setTableData(sortArr)
            setSpinnerShow(false)
        } catch (error) {
            setSpinnerShow(false)
        }
    }
    useEffect(() => {
        getTableData()
        if (selectUserStatus === 'idle') {
            dispatch(fetchAllUser())
        }
    }, [mtableData, deliveryDoneInfo, dispatch, selectUserStatus])

    const statusSwitch = (value, item) => {
        let data = <></>
        switch (value) {
            case 'Order Confirmed':
                data = <p className={SaleHisCss.confirmed}>{value}</p>
                break;
            case 'Packaging Complete':
                data = <div className={SaleHisCss.myStatus}>
                    <p className={SaleHisCss.packaging}>{value}</p>
                    <FaRightFromBracket onClick={(e) => { deliveryBoyAssign(e, item) }}/>
                </div>
                break;
            case 'Order Partial Return':
                data = <div className={SaleHisCss.myStatus}>
                    <p className={SaleHisCss.preturn}>{value}</p>
                    <FaRightFromBracket onClick={(e) => { deliveryBoyAssign(e, item) }}/>
                </div>
                break;
            case 'Delivery Agent Assigned':
                data = <div className={SaleHisCss.myStatus}>
                    <p className={SaleHisCss.deliveryboyassigned}>DA Assigned</p>
                    <FaCheck onClick={(e) => { deliveryAgentClick(e, item) }} />
                </div>
                break;
            default:
                data = <>{value}</>
                break;
        }
        return data
    }


    return (
        <section className={SaleHisCss.wrapper}>

                {activityView ? <ActivityViewModel popUp={activityView} setPopUp={setActivityView} /> : ""}

                {showUser && <CustomerInformationModel popUp={showUser} setPopUp={setShowUser} />}

                {showPackagingBox ? <PackagingPop myPackagingData={packagingData} setshowPackagingBox={setshowPackagingBox} tableData={tableData} /> : ""}

                {deliveryBoyData ? <DeliveryBoyAssign deliveryBoyData={deliveryBoyData} setDeliveryBoyData={setDeliveryBoyData} tableData={tableData} /> : ""}
                {deliveryDoneInfo ? <CollectOption deliveryDoneInfo={deliveryDoneInfo} setDeliveryDoneInfo={setDeliveryDoneInfo} tableData={tableData} setTableData={setTableData} /> : ""}
                
                <div className={SaleHisCss.holder}>                    
                <div className={SaleHisCss.head}>
                    <GobackButton />
                    <h2 >Sales History</h2>
                </div>
                    <LoadingShow ShowLodign={spinnerShow} />
                   <div className={SaleHisCss.search_holder}>
                   <SalesSearchComp passedFrom={'history'} setFilteredData={setTableData} />
                   </div>
                    <div className={SaleHisCss.table_wrapper}>
                        <table>
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>id</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Address</th>
                                    <th>Total Ammount</th>
                                    <th>Status</th>
                                    <th>Confirmed By</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.filter((fitem) => !fitem.fullReturn && fitem)
                                    .map((item, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td><Link to={`/sales/salesdetails/${item.id}`}>{'#' + item.id}</Link></td>
                                            <td>{ServerTimeToFormateTime(item.created_date)}</td>
                                            <td><span className={SaleHisCss.customerClick} onClick={() => customerClick(item)}>{item.name}</span></td>
                                            <td>{item.aphone ? item.aphone : item.phone}</td>
                                            <td>{item.address || 'pick up point'}</td>
                                            <td>{((parseInt(item.total_price) + item.deliveryCharge) - (item.total_orprice * item.discountPercent / 100) - item.rprice).toFixed(2)}</td>
                                            <td>{statusSwitch(item.status, item)}</td>
                                            <td>
                                            <span className={SaleHisCss.user_wrapper}>
                                            <img src={API_LINK + 'userimages/' + item.userImageName} alt={item.userImageName} />
                                                {item.userName}
                                            </span>
                                                </td>
                                            <td>
                                                <div className={SaleHisCss.action_buttons}>
                                                    {item.packaging ? "" : <Link onClick={(e) => { PackagingClick(e, item); document.body.style.overflow = 'hidden' }}> <FaShoppingBag /> </Link>}
                                                    <Link to={`/sales/salesdetails/${item.id}`}><FaInfoCircle/></Link>
                                                    <Link onClick={(e) => { activityViewClick(e, item); document.body.style.overflow = 'hidden' }}><FaBars /></Link>
                                                    {item.packaging ? "" : <Link to={`/sales/sale_history/sales_edit/${item.id}`}><FaRegEdit /></Link>}
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    )
}

export default SaleHistory


