import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { selectBrandList } from "../../../ReduxFiles/Slices/BrandListSlice";
import { selectCategoryList } from "../../../ReduxFiles/Slices/CategorySlice";
import {
  fetchAtributes,
  fetchUnits,
  selectMAUStatus,
  selectUnitList,
} from "../../../ReduxFiles/Slices/MAU_Slice";
import { CheckRatio } from "../../../api/GlobalFunctions";
import { GetLastItem } from "../../../api/ProductListApi";
import ItemInformationCss from "./css/ItemInformation.module.css";

const ItemInformation = ({ item, setstate, setItem }) => {
  const Brands = useSelector(selectBrandList);
  const itemCat = useSelector(selectCategoryList);
  const Units = useSelector(selectUnitList);
  const mauStatus = useSelector(selectMAUStatus);
  const dispatch = useDispatch();

  function onCategoryChange(e) {
    getItemCode(e.id);
  }
  async function getItemCode(value) {
    let itemCodeValue = "";
    let itemSlug = "";
    let attribute_id;
    if (value !== "") {
      itemCat.forEach((element) => {
        if (element.id === parseInt(value)) {
          itemCodeValue = element.code;
          itemSlug = element.slug;
          attribute_id = element.SelectedAttribute;
        }
      });
      let findProducts = await GetLastItem(value);
      let finalValue = 0;
      if (findProducts.length > 0) {
        finalValue = parseInt(findProducts[0].item_code) + 1;
      } else {
        finalValue = itemCodeValue + "00" + 1;
      }
      setItem({
        ...item,
        item_code: finalValue,
        itemSlug: itemSlug,
        attribute_id,
      });
    }
  }
  const [shwoItemImage, setShowItemImage] = useState({});

  function imageChange(e) {
    let name = e.target.name;
    let value = e.target.files[0];
    if (value) {
      CheckRatio(value, (isImageOK) => {
        if (isImageOK) {
          ImageShowOK(value, name);
        } else {
          e.target.value = null;
          setShowItemImage({ ...shwoItemImage, [name]: "" });
        }
      });
    } else {
      setShowItemImage({ ...shwoItemImage, [name]: "" });
    }
  }
  function ImageShowOK(mImage, identity) {
    let myurl = URL.createObjectURL(mImage);
    setShowItemImage({ ...shwoItemImage, [identity]: myurl });
  }

  async function SaveItem(e) {
    e.preventDefault();
    try {
      const form = e.target;
      const en_sort_title = form.en_sort_title.value;
      const en_long_title = form.en_long_title.value;
      const bn_sort_title = form.bn_sort_title.value;
      const bn_long_title = form.bn_long_title.value;
      const selected_catagory_id = form.selected_catagory_id.value;
      const item_code = form.item_code.value;
      const selected_brand_id = form.selected_brand_id.value;
      const ISBN_number = form.ISBN_number.value;
      const selectedUnit = form.selectedUnit.value;
      const itemImage = form.itemImage.files;
      const en_description = form.en_description.value;
      const bn_description = form.bn_description.value;
      const en_speicification = form.en_speicification.value;
      const bn_speicification = form.bn_speicification.value;
      setItem({
        ...item,
        en_sort_title,
        en_long_title,
        bn_sort_title,
        bn_long_title,
        selected_catagory_id,
        item_code,
        selected_brand_id,
        ISBN_number,
        selectedUnit,
        itemImage,
        en_description,
        bn_description,
        en_speicification,
        bn_speicification,
      });
      setstate(2);
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function OptionItem() {
    if (mauStatus.unitStatus === "idle") {
      dispatch(fetchUnits());
    }

    if (mauStatus.attributesStatus === "idle") {
      dispatch(fetchAtributes());
    }
  }

  useEffect(() => {
    OptionItem();
  }, []);

  const config = {
    useSplitMode: false,
    toolbarSticky: true,
    toolbarStickyOffset: 120,

    uploader: {
      insertImageAsBase64URI: true,
    },
    placeholder: "Type Your Content Here...",
    showXPathInStatusbar: false,
  };

  return (
    <section className={ItemInformationCss.iteminfo}>
      <form onSubmit={SaveItem} className={ItemInformationCss.my_form}>
        <div className={ItemInformationCss.input_wrapper}>
          <div className={ItemInformationCss.common}>
            <label>English Short Title*</label>
            <input
              name="en_sort_title"
              type="text"
              placeholder="English Short Title"
              required
            />
          </div>
          <div className={ItemInformationCss.common}>
            <label>English Long Title</label>
            <input
              name="en_long_title"
              type="text"
              placeholder="English Long Title"
              required
            />
          </div>
          <div className={ItemInformationCss.common}>
            <label>Bengali Short Title*</label>
            <input
              name="bn_sort_title"
              type="text"
              placeholder="Bengali Short Title"
              required
            />
          </div>
          <div className={ItemInformationCss.common}>
            <label>Bengali Long Title*</label>
            <input
              name="bn_long_title"
              type="text"
              placeholder="Bengali Long Title"
              required
            />
          </div>
          <div className={ItemInformationCss.common}>
            <label>Category</label>
            <Select
              options={itemCat}
              getOptionLabel={(option) => `${option.catName}`}
              getOptionValue={(option) => `${option.id}`}
              name="selected_catagory_id"
              onChange={onCategoryChange}
              required
              isClearable
            />
          </div>
          <div className={ItemInformationCss.common}>
            <label>Item Code</label>
            <input
              name="item_code"
              type="text"
              placeholder="item code"
              disabled
              defaultValue={item.item_code}
            />
          </div>

          <div className={ItemInformationCss.common}>
            <label>Brand</label>
            <Select
              options={Brands}
              getOptionLabel={(option) => `${option.BrandName}`}
              getOptionValue={(option) => `${option.id}`}
              name="selected_brand_id"
              required
              isClearable
            />
          </div>
          <div className={ItemInformationCss.common}>
            <label>ISBN Number</label>
            <input name="ISBN_number" type="text" placeholder="ISBN Number" />
          </div>

          <div className={ItemInformationCss.common}>
            <label>Unit</label>
            <Select
              options={Units}
              getOptionLabel={(option) => `${option.unit}`}
              getOptionValue={(option) => `${option.id}`}
              name="selectedUnit"
              required
              isClearable
            />
          </div>
        </div>

        <div className={ItemInformationCss.input_jodit}>
          <h4>Product Description (English)</h4>
          <JoditEditor
            value={item.en_description || ""}
            config={config}
            tabIndex={1}
            name="en_description"
          />
        </div>

        <div className={ItemInformationCss.input_jodit}>
          <h4>Product Description (Bengali)</h4>
          <JoditEditor
            value={item.bn_description || ""}
            config={config}
            tabIndex={1} // tabIndex of textarea
            name="bn_description"
          />
        </div>

        <div className={ItemInformationCss.input_jodit}>
          <h4>Product Specifications (English)</h4>
          <JoditEditor
            value={item.en_speicification || ""}
            config={config}
            tabIndex={1} // tabIndex of textarea
            name="en_speicification"
          />
        </div>

        <div className={ItemInformationCss.input_jodit}>
          <h4>Product Specifications (Bengali)</h4>
          <JoditEditor
            value={item.bn_speicification || ""}
            config={config}
            tabIndex={1} // tabIndex of textarea
            name="bn_speicification"
          />
        </div>
        <div className={ItemInformationCss.btn_group}>
          <button type={"submit"}>Save & Continue</button>
        </div>
      </form>
    </section>
  );
};

export default ItemInformation;
