import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ErrorPage() {
    const myNavigate = useNavigate();

    const navigateToErrorPage = useCallback(() => {
        myNavigate('/404.html');
        window.location.reload();
    }, [myNavigate]);

    useEffect(() => {
        navigateToErrorPage();
    }, [navigateToErrorPage]);

    return <></>;
}
