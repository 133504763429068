import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "../../ErrorPage";
import CompleteDetailsView from "./CompleteDetailsView";
import CompleteOrder from "./CompleteOrder";
import DeclineDetailView from "./DeclineDetailView";
import DeclinedOrders from "./DeclinedOrders";
import Invoice from "./Invoice";
import InvoiceReturn from "./InvoiceReturn";
import PendingOrderDetails from "./PendingOrderDetails";
import PendingSales from "./PendingSales";
import ReturnDetailsView from "./ReturnDetailsView";
import SaleHistory from "./SaleHistory";
import SalesDetailView from "./SalesDetailView";
import SalesEdit from "./SalesEdit";
import SalesReturn from "./SalesReturn";
import SalesReturnHistory from "./SalesReturnHistory";
import OfferItems from "./salesOffer/OfferItems";
import OfferReport from "./salesOffer/OfferReport";
import DeliveryAgentMain from "./deliveryManagment/DeliveryAgentMain";
import BogoMain from "./salesOffer/bogo/BogoMain";
import UnverifiedSales from "./UnverifiedSales";
import UnverifiedDetails from "./UnverifiedDetails";
import CreateOrder from "./CreateOrder";
import UnverifiedSalesDeclined from "./UnverifiedSalesDeclined";
export default function SalesRoutes() {
  return (
    <Routes>
      <Route path="/create-order" element={<CreateOrder />} />
      <Route path="/unverified" element={<UnverifiedSales />} />
      <Route
        path="/unverified-declined"
        element={<UnverifiedSalesDeclined />}
      />
      <Route path="/pending" element={<PendingSales />} />
      <Route path="/history" element={<SaleHistory />} />
      <Route path="/complete" element={<CompleteOrder />} />
      <Route path="/decline" element={<DeclinedOrders />} />
      <Route path="/sales-return" element={<SalesReturn />} />
      <Route path="/return-history" element={<SalesReturnHistory />} />

      <Route
        path="unverified_details/:orderid"
        element={<UnverifiedDetails />}
      />
      <Route path="details/:orderid" element={<PendingOrderDetails />} />
      <Route path="salesdetails/:orderid" element={<SalesDetailView />} />
      <Route
        path="complete_orders/:orderid"
        element={<CompleteDetailsView />}
      />
      <Route path="declinedetails/:orderid" element={<DeclineDetailView />} />
      <Route
        path="sales_return_History/:orderid"
        element={<ReturnDetailsView />}
      />
      <Route path="salesdetails/:orderid/invoice" element={<Invoice />} />
      <Route
        path="salesdetails/:orderid/invoice_return"
        element={<InvoiceReturn />}
      />
      <Route path="sale_history/sales_edit/:orderid" element={<SalesEdit />} />
      {/* others route */}
      <Route path="/offer-items" element={<OfferItems />} />
      <Route path="/offer-report" element={<OfferReport />} />
      <Route path="/delivery-manage/*" element={<DeliveryAgentMain />} />
      <Route path="/bogo/*" element={<BogoMain />} />

      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
}
