import React, { useState } from 'react';
import editMangeUser from './css/editManageUser.module.css'
import Select from 'react-select';
import { IoClose } from "react-icons/io5";
import { useDispatch } from 'react-redux'
import { UpdateBackUserData } from '../../../api/BackendUserApi';
import { fetchAllUser} from '../../../ReduxFiles/Slices/UserSlice'
import { toast } from 'react-toastify';
import { API_LINK } from '../../../api/APILink';
export default  function EditManageUser({setEditMagage,editData}) {
    const dispatch = useDispatch()
    const [selectedValue, setSelectedValue] = useState('');
    function onClose(){
        setEditMagage(false)
    }
    async function handleUpdate(e) {
        e.preventDefault()
        const from=e.target;
        const userName=from.userName.value;
        const fullName=from.fullName.value;
        const email=from.email.value;
        const phone=from.phone.value;
        const userbio=from.userbio.value;
        const userImage=from.userImage.files[0];
            const data=await UpdateBackUserData(editData.id,{userName,fullName,email,phone,userbio},userImage)
            if(data){
                toast.success('user update succesfully')
                dispatch(fetchAllUser())
                setEditMagage(false)
            }else{
                toast.warn(data.message)
            }
    }
  return (
    <div className={editMangeUser.wrapper_popup}>
        <div className={editMangeUser.holder}>
            <h3>Edit Manage User</h3>
            <button onClick={onClose} className={editMangeUser.close_button}><IoClose /></button>
           <form onSubmit={handleUpdate}>
                <div className={editMangeUser.input_wrapper}>
                    <label>User Name*</label>
                    <input type='text' defaultValue={editData.userName} name='userName'/>
                </div>
                <div className={editMangeUser.input_wrapper}>
                    <label>Full Name*</label>
                    <input type='text' defaultValue={editData.fullName} name='fullName' required />
                </div>
                <div className={editMangeUser.input_wrapper}>
                    <label>Email*</label>
                    <input type='text' defaultValue={editData.email} name='email' required />
                </div>
                <div className={editMangeUser.input_wrapper}>
                    <label>Phone*</label>
                    <input type='text' defaultValue={editData.phone} name='phone' required />
                </div>
                <div className={editMangeUser.input_wrapper}>
                <label>Bio*</label>
                <input type='text' defaultValue={editData.userbio} name='userbio' required />
                </div>

                <div className={editMangeUser.input_wrapper}>
                <label>Upload Image*</label>
                <input type='file' name='userImage'/>
                    <img src={`${API_LINK}/userimages/${editData.userImageName}`}  />
                </div> 
                <div className={editMangeUser.button_wrapper}>
                    <button>Update</button>
                </div>
           </form>
           
        </div>
    </div>
  )
}
