import HeaderImage from '../../assets/Headerimage.jpg'
import HeadetFooterCss from './HeadetFooter.module.css'

export const HeaderFooterWrapper = ({content}) => {
    function PrintPaper() {
        window.print()
    }
    return (
        <div className={HeadetFooterCss.invoice_main}>
            <button className={HeadetFooterCss.printBtn} onClick={PrintPaper}>Print</button>
            <div className={HeadetFooterCss.container} id="printInvoice" >
                    <div className={HeadetFooterCss.contents}>
                    <img src={HeaderImage} alt="invoice header" />
                        {content}
                    </div>
                </div>
        </div>
    )
}