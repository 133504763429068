import AllCombineCss from './css/AllCombine.module.css'

import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import Admin_footer from './AllContent/admin_footer'
//
import { useDispatch, useSelector } from 'react-redux'
import { fetchBrands } from './ReduxFiles/Slices/BrandListSlice'
import { fetchCategory, selectCategoryStatus } from './ReduxFiles/Slices/CategorySlice'
import { fetchProducts, setProductList, setProductStatus } from './ReduxFiles/Slices/ProductSlice'
import { fetchAllUser, selectIsLogedIn, setIsLogedIn, setUserInfo } from './ReduxFiles/Slices/UserSlice'
import { DecryptLocalData } from './api/BackendUserApi'

// new imports
import Dashboard from './AllContent/Dashboard/Dashboard'
import TopBar from './TopBar'
import SideNavBar from './SideNavBar'
import AllRoutes from './AllContent/item_management/AllRoutes'
import ProductManagement from './AllContent/item_management/ProductManagement'
import ErrorPage from './ErrorPage'
import SalesMain from './AllContent/sales/SalesMain'
import SalesRoutes from './AllContent/sales/SalesRoutes'
import PurchaseMain from './AllContent/purchase/PurchaseMain'
import PurchaseRoute from './AllContent/purchase/PurchaseRoute'
import ReportRoutes from './AllContent/Report/ReportRoutes'
import ReportMain from './AllContent/Report/ReportMain'
import AccountMain from './AllContent/Accounts/AccountMain'
import AccountRoutes from './AllContent/Accounts/AccountRoutes'
import ExpenseMain from './AllContent/Expense/ExpenseMain'
import ExpenseRoutes from './AllContent/Expense/ExpenseRoutes'
import ManageCustomerMain from './AllContent/manageCustomer/ManageCustomerMain'
import CustomerRoutes from './AllContent/manageCustomer/CustomerRoutes'
import SettingMain from './AllContent/Setting/SettingMain'
import SettingRoutes from './AllContent/Setting/SettingRoutes'

const AllCombine = () => {
    const isLogedIn = useSelector(selectIsLogedIn)
    const categoryStatus = useSelector(selectCategoryStatus)
    const myDispatch = useDispatch()
    const myNavigation = useNavigate()

   async function getAllProductList() {
        if (!isLogedIn) {
            const localUserData =  window.localStorage.getItem('userData')
         if (localUserData) {
            const decrytedData = DecryptLocalData(localUserData)
            let finalData = decrytedData && JSON.parse(decrytedData)
            myDispatch(setIsLogedIn(true))
            myDispatch(setUserInfo(finalData))
            if (finalData.userName) {
               let localProducts =  window.localStorage.getItem('allProduct')
               if (localProducts && !localProducts ==='undefined') {
                    myDispatch(setProductList(JSON.parse(localProducts)))
                    myDispatch(setProductStatus('success'))
               }else{
                   myDispatch(fetchProducts())
               }
               myDispatch(fetchAllUser())
            }else{
                myNavigation('/login')
            } 
         }else{
            myNavigation('/login')
        }
        }

    }  
  
    function getPrimaryData() {
       
        if (categoryStatus==='idle') {
           myDispatch(fetchCategory())
           myDispatch(fetchBrands())
        }
        
    }

    useEffect(()=>{
        getPrimaryData()
    },[])

    useEffect(()=>{
        getAllProductList()
    },[isLogedIn])


    return (
            <div className={AllCombineCss.container}>
                {isLogedIn ? <SideNavBar />:''}
                {isLogedIn ? <TopBar/>:''}

                <div className={AllCombineCss.Spacer}>
                    <Routes>
                        <Route path='/' element={<Dashboard />} />

                        <Route path='/products' element={<ProductManagement />} />
                        <Route path='/products/*' element={<AllRoutes />} />

                        <Route path='/sales' element={<SalesMain />} />
                        <Route path='/sales/*' element={<SalesRoutes />} />

                        <Route path='/purchase' element={<PurchaseMain />} />
                        <Route path='/purchase/*' element={<PurchaseRoute />} />

                        <Route path='/reports' element={<ReportMain />} />
                        <Route path='/reports/*' element={<ReportRoutes />} />
                        
                        <Route path='/accounts' element={<AccountMain />} />
                        <Route path='/accounts/*' element={<AccountRoutes />} />

                        <Route path='/expense' element={<ExpenseMain />} />
                        <Route path='/expense/*' element={<ExpenseRoutes />} />

                        <Route path='/manage-customer' element={<ManageCustomerMain/>} />
                        <Route path='/manage-customer/*' element={<CustomerRoutes />} />

                        <Route path='/site-setting' element={<SettingMain />} />
                        <Route path='/site-setting/*' element={<SettingRoutes />} />

                        <Route path='/*' element={<ErrorPage />} /> 
                    </Routes>
                </div>
                <Admin_footer /> 
            </div>
    )
}

export default AllCombine